import { Component, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IonSlides } from '@ionic/angular';

@Component({
  selector: 'app-video-modal',
  templateUrl: './video-modal.page.html',
  styleUrls: ['./video-modal.page.scss'],
})

export class VideoModalPage implements OnInit{

  @Input() playVideo: any;
  @Input() videoLevel: string;
  @Input() clickIndex: number;
  @ViewChild('slideWithNav', { static: false }) slideWithNav: IonSlides;
  checkval:boolean=false;
  slideDiv:any;
  newSliderArray:any=[];
  playVideoTest=[];
  clickIndexTest:any;

  constructor(public modalController:ModalController,private zone:NgZone) { }

  ngOnInit(){
    this.playVideoTest=this.playVideo;
    if(this.videoLevel=="image"){
      this.playVideoTest[this.clickIndex].selectedIndex=this.clickIndex;
      setTimeout(() => {
        for(let i=0; i<this.playVideoTest.length; i++){
          if(this.playVideoTest[i].media_type==1 || this.playVideoTest[i].media_type=="1"){
            this.newSliderArray.push({"media_path":this.playVideoTest[i].media_path, "selectedIndex":this.playVideoTest[i].selectedIndex,  "module_id":this.playVideoTest[i].module_id || "" });
          }
          if(i==this.playVideoTest.length-1){

            this.clickIndexTest=this.newSliderArray.findIndex(x => x.selectedIndex != undefined);
          }
        }
        this.slideDiv={
          speed:"500",initialSlide:this.clickIndexTest,slidesPerView:1,spaceBetween:10,pager:true,
        }
        this.checkval=true;
      }, 500);
    }
  }
  // getIndex() {
  //   this.slideWithNav.nativeElement.getActiveIndex().then(index => {
  //     console.log(index);
  //  });
  // }

  dismissVideo(){
    this.zone.run(()=>{
      this.modalController.dismiss({
        'dismissed': true
      }).then(()=>{
        if(this.videoLevel=="image"){
          this.playVideoTest[this.clickIndex].selectedIndex=undefined;
        }
        this.playVideo=[];
        this.newSliderArray=[];
        this.clickIndex=undefined;
        this.clickIndexTest=undefined;
      });
    });
  }

}

// import { Component, Input, NgZone, OnInit, ViewChild } from '@angular/core';
// import { ModalController } from '@ionic/angular';
// import { PhotoViewer } from '@ionic-native/photo-viewer/ngx';
// import { IonSlides } from '@ionic/angular';

// @Component({
//   selector: 'app-video-modal',
//   templateUrl: './video-modal.page.html',
//   styleUrls: ['./video-modal.page.scss'],
// })

// export class VideoModalPage implements OnInit{

//   @Input() playVideo: any;
//   @Input() videoLevel: string;
//   @Input() clickIndex: number;
//   @ViewChild('slideWithNav', { static: false }) slideWithNav: IonSlides;
//   checkval:boolean=false;
//   slideDiv:any;
//   newSliderArray:any=[];
//   playVideoTest=[];

//   constructor(public modalController:ModalController,private photoViewer:PhotoViewer,private zone:NgZone) {}

//   ngOnInit(){
//     this.playVideoTest=this.playVideo;
//     console.log("previouse page data==", this.playVideo);
//     // console.log("data videoLevel==", this.videoLevel);
//     console.log("selectedIndex==", this.clickIndex);
//     if(this.videoLevel=="image"){
//       this.playVideoTest[this.clickIndex].selectedIndex=this.clickIndex;
//       console.log("selectedIndex key add in array ==", this.playVideoTest);
//       setTimeout(() => {
//         console.log("ModalController video data2==", this.playVideoTest);
//         for(let i=0; i<this.playVideoTest.length; i++){
//           if(this.playVideoTest[i].media_type==1 || this.playVideoTest[i].media_type=="1"){
//             console.log("ModalController video data2==", this.playVideoTest);
//             this.newSliderArray.push({"media_path":this.playVideoTest[i].media_path});
//             if(this.playVideoTest[i].selectedIndex && this.playVideoTest[i].selectedIndex!=''){
//               this.clickIndex=i;
//               console.log("yoyoyo ==", this.clickIndex);
//             }
//           }
//         }
//         console.log("ModalController new array==", this.newSliderArray);
//         console.log("ModalController new clickIndex==", this.clickIndex);
//         this.slideDiv={
//           speed:"500",initialSlide:this.clickIndex,slidesPerView:1,spaceBetween:10,pager:true,
//         }
//         this.checkval=true;
//       }, 500);
//     }
//   }

//   dismissVideo(){
//     this.zone.run(()=>{
//       this.modalController.dismiss({
//         'dismissed': true
//       }).then(()=>{
//         this.playVideoTest[this.clickIndex].selectedIndex='';
//         this.playVideo=[];
//         this.newSliderArray=[];
//         this.clickIndex=undefined;
//         console.log("ModalController dissmiss==", this.playVideoTest);
//       });
//     });
//   }
// }
