import { Component, ViewChildren, NgZone, QueryList } from '@angular/core';
import { Platform, MenuController, IonRouterOutlet, NavController, AlertController, ModalController } from '@ionic/angular';
// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';
import { ApiservicesService } from './provider/apiservices.service';
import { URLS } from 'src/assets/constant';
import { Network } from '@ionic-native/network/ngx';
import { DataTransferService } from './services/data-transfer.service';
import { LocalStorageService } from './services/local-storage.service';
import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';   // this line is for Android
// import { FCM } from '@ionic-native/fcm/ngx';   // this line is for IOS
import { Storage } from '@ionic/storage-angular';
import { SplashPage } from './splash/splash.page';


declare var navigator;
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})

export class AppComponent{
  
  @ViewChildren(IonRouterOutlet) routerOutlets: QueryList<IonRouterOutlet>;
  menuItems:any=[];
  showNetworkDiv:boolean=false;
  pushIsinBackground:boolean=false;
  fcmGenerationRetryCount:number=0;
  bannerImg:any;
  lastTimeBackPress = 0;
  timePeriodToExit = 2000;
  themeColor:any;
  fcmToken:any;
  mainData: any;
  
  constructor(public platform:Platform,public apiservices:ApiservicesService,private navCtrl:NavController,public modalCtrl:ModalController,
    // private splashScreen:SplashScreen,
    private statusBar:StatusBar,private zone:NgZone, public menuCtrl:MenuController,private network:Network,private fcm:FCM,
    private sendData:DataTransferService,private router:Router,private storageService:LocalStorageService,private storage: Storage,
    private alertController:AlertController) {
    this.storageService.createDB();

    // alert("hello new app");
    this.initializeApp();
    // this is only for android start plz comment this section for ios start
    // this.platform.ready().then(() => {
    //   // if (this.platform.is("android")) {
    //   //   this.storageService.getStorage().then(storedData =>{
    //   //     // login_token
    //   //     if(storedData.loginData){
    //   //       // this.fcm.getInitialPushPayload().then((payload) => {
    //   //       //   if(payload.wasTapped) {
    //   //       //     setTimeout(() => {
    //   //       //       this.apiservices.checkcondition(payload);
    //   //       //     }, 6000);
    //   //       //   }
    //   //       // });
    //   //     }
    //   //   });
    //   // }
    // });
    // this is only for android end plz comment this section for ios start
  }

  ngOnInit(){
    this.platform.ready().then(() => {
      this.apiservices.generateDeviceId();
      this.menuCtrl.swipeGesture(false);
      if (this.platform.is("android")) {
        this.backButtonEvent();
      }
    });
  }
  async showModal(){
    let splash=await this.modalCtrl.create({
      component:SplashPage
    });
    await splash.present();
  }

  initializeApp(){
    if (this.platform.is("ios") || this.platform.is('android')) {
      // // for app android and Ios start
      this.statusBar.styleDefault();
      this.showModal();
      this.statusBar.overlaysWebView(false);
      // // for app android and ios end 
      this.generateFCMToken();
    }
    if(this.platform.is('ios')){
      this.apiservices.dignosticPermissionFunction();
    }
    this.apiservices.takePermission();
    this.apiservices.generateDeviceId();

    this.storageService.getStorage().then(storedData =>{
      // Benepik Theme matching color Start
      document.querySelector('body').style.setProperty('--benepikBtnColor', '#73b72e');
      document.querySelector('body').style.setProperty('--benepikTextColor', '#73b72e');
      this.Maintainance();
      // console.log('staoredDta new',storedData);
      if(storedData!=null && storedData.loginData){
        // this.Maintainance();
        // alert("stored Data=="+ storedData);
        // console.log('staoredDta',storedData);
        this.navCtrl.navigateRoot('/tabs/tab1');
      }else{
        // this.Maintainance();
        if(this.platform.is('desktop')){
          this.navCtrl.navigateRoot('/login');
        }else{
          this.navCtrl.navigateRoot('/welcome-screens');
        }
  
      }
      setTimeout(() => {
        if (this.platform.is("android")) {
          this.backButtonEvent(); 
        }
      }, 1500);
    });
    this.networkAvailability('withoutClick');
  }

  async generateFCMToken() {
    let that = this;
    this.platform.ready().then(() => {
      if (this.platform.is("cordova")) {
        that.fcm.subscribeToTopic('benepikOne');
        // console.log("call FCM and get token0.0==");
        // console.log("call FCM platform==", that.platform);
        that.fcm.onNotification().subscribe((data) => {
          // console.log("data receive from server call FCM == ", data);
          if (data.wasTapped) {
            that.pushIsinBackground = true;
            setTimeout(() => {
              that.checkcondition(data);
            }, 3000);
          } else {
            if (that.platform.is('ios')) {
              that.pushAlertView(data.aps.alert.title, data.aps.alert.body, data);
            } else {
              // that.pushAlertView(data.title+' '+'New Notification', data.body, data);
              that.pushAlertView(data.title, data.body, data);
            }
          };
          that.fcm.unsubscribeFromTopic('benepikOne');
        });
        this.fcm.getToken().then(token => {
          // console.log("call FCM and get token0==", token);
          if (token) {
            this.fcmToken = token;
            // console.log("call FCM If Token==", this.fcmToken);
            this.storage.set('fcmToken', this.fcmToken);
          } else {
            // console.log("call FCM else Token==", token);
            if (this.fcmGenerationRetryCount <= 2) {
              this.generateFCMToken();
              this.fcmGenerationRetryCount = this.fcmGenerationRetryCount + 1;
            }
          }
        });
        // console.log("call FCM and get token1.1==");
        this.fcm.onTokenRefresh().subscribe(token => {
          // console.log("call FCM and get token1==", token);
        });
      } else {
        let fcmDummyToken = "app_is_running_in_browser";
        this.storage.set('fcmToken', fcmDummyToken);
        // console.log("call FCM static FCM Token==", fcmDummyToken);
      }
    })
  }

  async pushAlertView(pushTitle, pushMessage, pushData) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: pushTitle,
      message: pushMessage,
      backdropDismiss: false,
      buttons: [
        {
          text: 'Ignore',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            // console.log("call FCM cancel/Ignore Push Alert==", blah);
          }
        }, {
          text: 'View',
          handler: () => {
            // console.log("call FCM View/Redirect Push Alert==", pushData);
            this.checkcondition(pushData);
          }
        }
      ]
    });
    await alert.present();
  }

  checkcondition(pushAllData) {
  //   this.router.routeReuseStrategy.shouldReuseRoute = function() {
  //     return false;
  // };
  //   console.log("call FCM push redirection data==0000000000", pushAllData);
    // console.log("call FCM checkConditionData 12==", pushAllData);
    this.sendData.alldata = pushAllData;  // this data transfer use for "notice-detail", "hr-policy-detail"
    this.sendData.myParam = pushAllData;  // this data transfer use for "communication-detail", "say-cheers-detail"
    // this.router.onSameUrlNavigation = 'reload';
    let data={
      "commingFrom":"noticeList"
    }
    this.sendData.commingPageName=data;
    this.router.navigate([pushAllData.redirect_page]);
    // location.reload();
    // this.router.navigateByUrl(pushAllData.redirect_page);
    // this.router.navigate(['/notice-detail']);
    if (this.pushIsinBackground == true) {
      //hide loader in case of backfround push
      // console.log("call FCM App Position in background==", this.pushIsinBackground);
      // this.hideLoader();
    } else {
      // console.log("call FCM App Position in foreground==", this.pushIsinBackground);
    }
  }








  networkAvailability(value){
    if(value=="click"){
      this.apiservices.showLoader();
      setTimeout(()=>{
        this.apiservices.hideLoader();
        this.apiservices.showToastMessage("No Internet Connection",'top',3000,'redBg');
      },3000);
    }
    this.network.onDisconnect().subscribe(() => {
      this.showNetworkDiv=true;
    });
    // watch network for a connection
    this.network.onConnect().subscribe(() => {
      // We just got a connection but we need to wait briefly
      // before we determine the connection type. Might need to wait.
      // prior to doing any api requests as well.
      this.showNetworkDiv=false;
    });
  }

  backButtonEvent(){
    // alert('app component current view is==> '+ this.router.url);
    this.platform.backButton.subscribeWithPriority(10, () => {
      this.routerOutlets.forEach(async(outlet: IonRouterOutlet) => {
        if(this.router.url == "/upload-profile-picture" || this.router.url == "/qualification" || 
        this.router.url =="/welcome-login" ){
          this.apiservices.showToastMessage("Sorry, You can't go back.", 'top', 3000, 'redBg');
        }else if (this.router.url == "/tabs/tab1" || this.router.url=="/force-optional-update" || this.router.url=="/maintenance" || this.router.url=="/welcome-screens" || this.router.url=="/organisation-name" || this.router.url=="/create-account" || this.router.url=="/question-score-card" || this.router.url=='/tabs'|| this.router.url=='/') {
          if (new Date().getTime() - this.lastTimeBackPress >= this.timePeriodToExit) {
            this.lastTimeBackPress = new Date().getTime();
            this.presentAlertConfirm();
          } else {
            navigator['app'].exitApp();
          }
        }else if( this.router.url == "/tabs/tab2" || this.router.url == "/tabs/tab3" || this.router.url=="/say-cheers-board" ){
          this.router.navigateByUrl('tabs/tab1');
        }else if (this.router.url == "/say-cheers-message" ){
          if(this.apiservices.previewData==true){
            this.apiservices.previewData=false;
          }else{
            outlet.pop();
          }
        }else if (this.router.url == "/profile-picture" ){
          outlet.pop();
        }else{
          if (outlet && outlet.canGoBack()) {
            if(this.apiservices.disableHardware==true &&  this.router.url=="/redeem-my-order-detail"){
              this.router.navigateByUrl("/tabs/tab1");
            }else{
              outlet.pop();
            }
          }
        }
      });
    });
  }

  async presentAlertConfirm(){  
    const alert = await this.alertController.create({
      // header: 'Confirm!',
      message: 'Are you sure you want to exit the app?',
      buttons: [{
        text: 'Cancel',
        role: 'cancel',
        cssClass: 'secondary',
        handler: (blah) => {}
      }, {
        text: 'Close App',
        handler: () => {
          navigator['app'].exitApp();
        }
      }]
    });  
    await alert.present();
  }

  Maintainance(){
    this.storageService.getStorage().then(storedData =>{
    let apiKey={};
    this.apiservices.apiCallWithLoginToken(URLS.maintainanceApiUrl, apiKey).subscribe((result) => { 
      if(result.status==1 || result.status=='1'){
        this.themeColor=result.data.theme;
        this.apiservices.termsAndConditionData=result.data.tnc;
        this.apiservices.privacyPolicyData=result.data.privacy_policy;
        this.apiservices.organisationNameData=result.data.settings;
        this.apiservices.iosRegistrationButton=result.data.isRegisterEnable;
        document.querySelector('body').style.setProperty('--headerColor',this.themeColor.headerColor);
        document.querySelector('body').style.setProperty('--headerTitleOrMenu',this.themeColor.headerTitleOrMenu);
        document.querySelector('body').style.setProperty('--headerLightColor',this.themeColor.headerLightColor);
        document.querySelector('body').style.setProperty('--whiteHeader',this.themeColor.whiteHeader);
        document.querySelector('body').style.setProperty('--blackHeaderText',this.themeColor.blackHeaderText);
        document.querySelector('body').style.setProperty('--outlineBorderButton',this.themeColor.outlineBorderButton);
        document.querySelector('body').style.setProperty('--transparentColor',this.themeColor.transparentColor);
        document.querySelector('body').style.setProperty('--redTextColor',this.themeColor.redTextColor);
        document.querySelector('body').style.setProperty('--blackTextColor',this.themeColor.blackTextColor);
        document.querySelector('body').style.setProperty('--yellowTextColor',this.themeColor.yellowTextColor);
        document.querySelector('body').style.setProperty('--toastSuccessColorApp',this.themeColor.toastSuccessColorApp);
        document.querySelector('body').style.setProperty('--toastFailColorRed',this.themeColor.toastFailColorRed);
        document.querySelector('body').style.setProperty('--nonSelectedTabs',this.themeColor.nonSelectedTabs);
        document.querySelector('body').style.setProperty('--nonSelectedTabsBorder',this.themeColor.nonSelectedTabsBorder);
        document.querySelector('body').style.setProperty('--nonSelectedTabsBg',this.themeColor.nonSelectedTabsBg);
        document.querySelector('body').style.setProperty('--selectedTabBg',this.themeColor.selectedTabBg);
        document.querySelector('body').style.setProperty('--topStatusBarColor',this.themeColor.topStatusBarColor);  
        if (this.platform.is("ios") || this.platform.is('android')) {
          this.statusBar.backgroundColorByHexString(this.themeColor.topStatusBarColor);
          // this.statusBar.backgroundColorByHexString('var(--topStatusBarColor');
          
        }
        if(result.data.app_down==1 || result.data.app_down=="1"){ // is_down
          this.sendData.myParam={"allData":result.data}
          this.mainData=result.data.app_down;
          this.router.navigate(["/maintenance"]);
        }
      }else{
        this.apiservices.showToastMessage(result.message, 'top', 3000, 'redBg');
      }
    });
  });
  }

  hideSideMenu(){
    this.menuCtrl.toggle();
  }

  goToPage(value){
    this.zone.run(()=>{
      this.menuCtrl.toggle();
      if(value.link!=''){
        if(value.identifier=="privacypolicy"){
          var data={"pageName":"privacyPolicy","commingName":"sideMenu" }
          this.sendData.alldata=data;
          this.router.navigate([value.link]);
        }else if(value.identifier=="termofuse"){
          var data={"pageName":"termsCondition","commingName":"sideMenu"}
          this.sendData.alldata=data;
          this.router.navigate([value.link]);
        }else if(value.identifier=="external"){
          this.apiservices.iab.create(value.link, "_system", this.apiservices.options);
        }else{
          this.router.navigate([value.link]);
        }
      }else{
        this.logOutConfirmation();
      }
    });
  }

  

  async logOutConfirmation() {
    const alert = await this.alertController.create({
      message: "Are you sure, you want to logout from this App",
      buttons: [{
          text: 'Not now',
          handler: () => {
          // console.log("logout App status Not Now==");
          }
        },{
          text: 'Logout',
          handler: () => {
          this.logOut();           
          // console.log("logout App status Yes, sure==");
          }
        }
      ]
    });
    await alert.present();
  }

  logOut() {
    this.apiservices.showLoader();
    let apiKey = {};
    this.apiservices.apiCallWithLoginToken(URLS.logoutApiUrl, apiKey).subscribe((result) => {
      this.apiservices.hideLoader();
        if (result.status == '1' || result.status == 1) {
        this.apiservices.showToastMessage(result.message, 'top', 3000, 'greenBg');
        this.storageService.remove('allStoreData').then(stor_res=>{        
          this.navCtrl.navigateRoot('/welcome-screens');
          // this.navCtrl.navigateRoot('/login');
          // window.location.reload();

          // if(this.platform.is('android')){
          //    navigator['app'].exitApp();
          // }else{
            // window.location.reload();
          // }
        });
        this.apiservices.generateDeviceId();
        setTimeout(() => {
          this.Maintainance();
        }, 800);
      } else {
      }
    }, err => {
      this.apiservices.hideLoader();
    });
  }





}