import { Injectable } from '@angular/core';
// import { Storage } from '@ionic/storage-angular';
import { Storage } from '@ionic/storage-angular';
@Injectable({
  providedIn: 'root'
})

export class LocalStorageService {
  private _storage: Storage | null = null;
  constructor(private storage:Storage) { }





  setStorage(key, value){
    // console.log('storage data key==> ', key);
    // console.log('storage data value==> ', value);
    this.storage.get('allStoreData').then(storedData=>{
      
      // console.log('call storage service1==> ', storedData); 
      // console.log('call storage service2==> ', storedData);  
      if(storedData){
        storedData[key]=value;
        this.storage.set('allStoreData', storedData).then(res=>{
          // console.log('storage data==> ', res);
        });
      }else{
        // console.log('******==> ', storedData);    
        // console.log('****** key==> ', key);    
        // console.log('****** value==> ', value);    
        let obj={};
        obj[key]=value;
        this.storage.set('allStoreData', obj).then(res=>{
          // console.log('storage data null obj==> ', res);
        });
      }
    });
  }


  // to get a key/value pair
  async getStorage(): Promise<any> {
    try {
      const result = await this.storage.get('allStoreData');
      if (result != null) {
        return result;
      }
      return null;
    } catch (reason) {
      // console.log(reason);
      return null;
    }
  }



  // remove a single key value:
  remove(key: string){
   return this.storage.remove(key);
  }

  //  delete all data from your application:
  clear(){
    this.storage.clear();
  }

  
  async createDB() {
    // If using a custom driver:
    // await this.storage.defineDriver(MyCustomDriver)
    // await this.storage.create();
    const storage = await this.storage.create();
    this._storage = storage;
  }



}
