import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [

  // {
  //   path:"",
  //   redirectTo:'login',
  //   pathMatch:'full'
  // },
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./LoginModuleNew/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./maintenance/maintenance.module').then( m => m.MaintenancePageModule)
  },
  {
    path: 'welcome-screens',
    loadChildren: () => import('./welcome-screens/welcome-screens.module').then( m => m.WelcomeScreensPageModule)
  },
  {
    path: 'force-optional-update',
    loadChildren: () => import('./force-optional-update/force-optional-update.module').then( m => m.ForceOptionalUpdatePageModule)
  },  
  {
    path: 'organisation-name',
    loadChildren: () => import('./loginModule/organisation-name/organisation-name.module').then( m => m.OrganisationNamePageModule)
  },
  {
    path: 'enter-number',
    loadChildren: () => import('./loginModule/enter-number/enter-number.module').then( m => m.EnterNumberPageModule)
  },
  {
    path: 'enter-otp',
    loadChildren: () => import('./loginModule/enter-otp/enter-otp.module').then( m => m.EnterOtpPageModule)
  },
  {
    path: 'create-account',
    loadChildren: () => import('./loginModule/create-account/create-account.module').then( m => m.CreateAccountPageModule)
  },
  {
    path: 'profile-picture',
    loadChildren: () => import('./loginModule/profile-picture/profile-picture.module').then( m => m.ProfilePicturePageModule)
  },
  {
    path: 'hr-policy-list',
    loadChildren: () => import('./hrPolicyModule/hr-policy-list/hr-policy-list.module').then( m => m.HrPolicyListPageModule)
  },
  {
    path: 'hr-policy-detail',
    loadChildren: () => import('./hrPolicyModule/hr-policy-detail/hr-policy-detail.module').then( m => m.HrPolicyDetailPageModule)
  },
  {
    path: 'digitals-signature',
    loadChildren: () => import('./hrPolicyModule/digitals-signature/digitals-signature.module').then( m => m.DigitalsSignaturePageModule)
  },
  {
    path: 'hr-questions',
    loadChildren: () => import('./hrPolicyModule/hr-questions/hr-questions.module').then( m => m.HrQuestionsPageModule)
  },
  {
    path: 'policy-otp',
    loadChildren: () => import('./hrPolicyModule/policy-otp/policy-otp.module').then( m => m.PolicyOtpPageModule)
  },
  {
    path: 'question-score-card',
    loadChildren: () => import('./hrPolicyModule/question-score-card/question-score-card.module').then( m => m.QuestionScoreCardPageModule)
  },
  {
    path: 'notice-list',
    loadChildren: () => import('./noticeBoardModule/notice-list/notice-list.module').then( m => m.NoticeListPageModule)
  },
  {
    path: 'notice-detail',
    loadChildren: () => import('./noticeBoardModule/notice-detail/notice-detail.module').then( m => m.NoticeDetailPageModule)
  },
  {
    path: 'notice-archive',
    loadChildren: () => import('./noticeBoardModule/notice-archive/notice-archive.module').then( m => m.NoticeArchivePageModule)
  },
  {
    path: 'community-list',
    loadChildren: () => import('./communicationModule/community-list/community-list.module').then( m => m.CommunityListPageModule)
  },
  {
    path: 'community-detail',
    loadChildren: () => import('./communicationModule/community-detail/community-detail.module').then( m => m.CommunityDetailPageModule)
  },
  {
    path: 'say-cheers-list',
    loadChildren: () => import('./sayCheersModule/say-cheers-list/say-cheers-list.module').then( m => m.SayCheersListPageModule)
  },
  {
    path: 'say-cheers-card',
    loadChildren: () => import('./sayCheersModule/say-cheers-card/say-cheers-card.module').then( m => m.SayCheersCardPageModule)
  },
  {
    path: 'say-cheers-message',
    loadChildren: () => import('./sayCheersModule/say-cheers-message/say-cheers-message.module').then( m => m.SayCheersMessagePageModule)
  },
  {
    path: 'say-cheers-board',
    loadChildren: () => import('./sayCheersModule/say-cheers-board/say-cheers-board.module').then( m => m.SayCheersBoardPageModule)
  },
  {
    path: 'say-cheers-detail',
    loadChildren: () => import('./sayCheersModule/say-cheers-detail/say-cheers-detail.module').then( m => m.SayCheersDetailPageModule)
  },
  {
    path: 'say-cheers-slide-detail',
    loadChildren: () => import('./sayCheersModule/say-cheers-slide-detail/say-cheers-slide-detail.module').then( m => m.SayCheersSlideDetailPageModule)
  },
  {
    path: 'say-cheers-badges',
    loadChildren: () => import('./sayCheersModule/say-cheers-badges/say-cheers-badges.module').then( m => m.SayCheersBadgesPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'update-mob-email',
    loadChildren: () => import('./update-mob-email/update-mob-email.module').then( m => m.UpdateMobEmailPageModule)
  },
  {
    path: 'like-list',
    loadChildren: () => import('./like-list/like-list.module').then( m => m.LikeListPageModule)
  },
  {
    path: 'redeem-home',
    loadChildren: () => import('./RedeemModule/redeem-home/redeem-home.module').then( m => m.RedeemHomePageModule)
  },
  {
    path: 'accountstatement',
    loadChildren: () => import('./RedeemModule/accountstatement/accountstatement.module').then( m => m.AccountstatementPageModule)
  },
  {
    path: 'redeem-my-order-list',
    loadChildren: () => import('./RedeemModule/redeem-my-order-list/redeem-my-order-list.module').then( m => m.RedeemMyOrderListPageModule)
  },
  {
    path: 'redeem-my-order-detail',
    loadChildren: () => import('./RedeemModule/redeem-my-order-detail/redeem-my-order-detail.module').then( m => m.RedeemMyOrderDetailPageModule)
  },
  {
    path: 'redeem-information',
    loadChildren: () => import('./RedeemModule/redeem-information/redeem-information.module').then( m => m.RedeemInformationPageModule)
  },
  {
    path: 'redeem-voucher-category-list',
    loadChildren: () => import('./RedeemModule/redeem-voucher-category-list/redeem-voucher-category-list.module').then( m => m.RedeemVoucherCategoryListPageModule)
  },
  {
    path: 'voucher-list',
    loadChildren: () => import('./RedeemModule/voucher-list/voucher-list.module').then( m => m.VoucherListPageModule)
  },
  {
    path: 'voucher-list-detail',
    loadChildren: () => import('./RedeemModule/voucher-list-detail/voucher-list-detail.module').then( m => m.VoucherListDetailPageModule)
  },
  {
    path: 'redeem-otp',
    loadChildren: () => import('./RedeemModule/redeem-otp/redeem-otp.module').then( m => m.RedeemOtpPageModule)
  },
  {
    path: 'terms-and-privacy',
    loadChildren: () => import('./loginModule/terms-and-privacy/terms-and-privacy.module').then( m => m.TermsAndPrivacyPageModule)
  },
  {
    path: 'contactus',
    loadChildren: () => import('./contactus/contactus.module').then( m => m.ContactusPageModule)
  },
  {
    path: 'notice-otp',
    loadChildren: () => import('./noticeBoardModule/notice-otp/notice-otp.module').then( m => m.NoticeOtpPageModule)
  },
  {
    path: 'video-modal',
    loadChildren: () => import('./video-modal/video-modal.module').then( m => m.VideoModalPageModule)
  },
  {
    path: 'contact-hr',
    loadChildren: () => import('./loginModule/contact-hr/contact-hr.module').then( m => m.ContactHrPageModule)
  },
  {
    path: 'ios-register',
    loadChildren: () => import('./loginModule/ios-register/ios-register.module').then( m => m.IosRegisterPageModule)
  },
  {
    path: 'dynamic-iframe',
    loadChildren: () => import('./dynamicModule/dynamic-iframe/dynamic-iframe.module').then( m => m.DynamicIframePageModule)
  },
  {
    path: 'award-list',
    loadChildren: () => import('./R&RModule/award-list/award-list.module').then( m => m.AwardListPageModule)
  },
  {
    path: 'award-dashboard',
    loadChildren: () => import('./R&RModule/award-dashboard/award-dashboard.module').then( m => m.AwardDashboardPageModule)
  },
  {
    path: 'otp-verify',
    loadChildren: () => import('./LoginModuleNew/otp-verify/otp-verify.module').then( m => m.OtpVerifyPageModule)
  },
  {
    path: 'welcome-login',
    loadChildren: () => import('./LoginModuleNew/welcome-login/welcome-login.module').then( m => m.WelcomeLoginPageModule)
  },
  {
    path: 'upload-profile-picture',
    loadChildren: () => import('./LoginModuleNew/upload-profile-picture/upload-profile-picture.module').then( m => m.UploadProfilePicturePageModule)
  },
  {
    path: 'qualification',
    loadChildren: () => import('./LoginModuleNew/qualification/qualification.module').then( m => m.QualificationPageModule)
  },
  {
    path: 'qualification-form',
    loadChildren: () => import('./LoginModuleNew/qualification-form/qualification-form.module').then( m => m.QualificationFormPageModule)
  },
  {
    path: 'qualification-priview',
    loadChildren: () => import('./LoginModuleNew/qualification-priview/qualification-priview.module').then( m => m.QualificationPriviewPageModule)
  },
  {
    path: 'welcome-aboard-list',
    loadChildren: () => import('./WelcomeAboardModule/welcome-aboard-list/welcome-aboard-list.module').then( m => m.WelcomeAboardListPageModule)
  },
  {
    path: 'idea',
    loadChildren: () => import('./IdeaBoxModule/idea/idea.module').then( m => m.IdeaPageModule)
  },
  {
    path: 'idea-history',
    loadChildren: () => import('./IdeaBoxModule/idea-history/idea-history.module').then( m => m.IdeaHistoryPageModule)
  },
  {
    path: 'birthday-anniversary-list',
    loadChildren: () => import('./birthaDayAnniversaryModule/birthday-anniversary-list/birthday-anniversary-list.module').then( m => m.BirthdayAnniversaryListPageModule)
  },
  {
    path: 'birthday-aniversray-detail',
    loadChildren: () => import('./birthaDayAnniversaryModule/birthday-aniversray-detail/birthday-aniversray-detail.module').then( m => m.BirthdayAniversrayDetailPageModule)
  },
  {
    path: 'award-detail',
    loadChildren: () => import('./R&RModule/award-detail/award-detail.module').then( m => m.AwardDetailPageModule)
  },
  {
    path: 'filter-list',
    loadChildren: () => import('./R&RModule/filter-list/filter-list.module').then( m => m.FilterListPageModule)
  },
  {
    path: 'awarddashboard-detail',
    loadChildren: () => import('./R&RModule/awarddashboard-detail/awarddashboard-detail.module').then( m => m.AwarddashboardDetailPageModule)
  },
  {
    path: 'award-employee-list',
    loadChildren: () => import('./R&RModule/award-employee-list/award-employee-list.module').then( m => m.AwardEmployeeListPageModule)
  },
  {
    path: 'award-employee-preview',
    loadChildren: () => import('./R&RModule/award-employee-preview/award-employee-preview.module').then( m => m.AwardEmployeePreviewPageModule)
  },
  {
    path: 'myactivity-detail',
    loadChildren: () => import('./R&RModule/myactivity-detail/myactivity-detail.module').then( m => m.MyactivityDetailPageModule)
  },
  {
    path: 'hr-policy-category',
    loadChildren: () => import('./hrPolicyModule/hr-policy-category/hr-policy-category.module').then( m => m.HrPolicyCategoryPageModule)
  },
  {
    path: 'my-award-list',
    loadChildren: () => import('./myAwardModule/my-award-list/my-award-list.module').then( m => m.MyAwardListPageModule)
  },
  {
    path: 'my-award-detail',
    loadChildren: () => import('./myAwardModule/my-award-detail/my-award-detail.module').then( m => m.MyAwardDetailPageModule)
  },
  {
    path: 'award-nominee-list',
    loadChildren: () => import('./R&RModule/award-nominee-list/award-nominee-list.module').then( m => m.AwardNomineeListPageModule)
  },
  {
    path: 'splash',
    loadChildren: () => import('./splash/splash.module').then( m => m.SplashPageModule)
  },
  {
    path: 'nominate-award-list',
    loadChildren: () => import('./R&RModule/nominate-award-list/nominate-award-list.module').then( m => m.NominateAwardListPageModule)
  },

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
